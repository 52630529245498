.notification {
    position: relative;
    min-height: 55px;
    width: 100%;
    padding: 18px 17px 19px 60px;
    font: 600 14px / calc(17 / 14) var(--font-family);
    color: #000000;
}

.notification p {
    margin: 0;
}

.notification:not(:first-child) {
    margin: 12px 0 0;
}

.warning {
    background-color: #fffbbb;
}

.warning:before {
    position: absolute;
    content: '\E623';
    top: 15px;
    font: normal 21px 'Admin Icons';
    left: 22px;
    color: #eb5202;
}

.error {
    background-color: #ffcccc;
}

.error:before {
    position: absolute;
    content: '\E632';
    top: 19px;
    font: normal 16px 'Admin Icons';
    left: 26px;
    color: red;
}

.success {
    background-color: #fffbbb;
}

.success:before {
    position: absolute;
    content: '\E62D️';
    top: 16px;
    font: normal 21px 'Admin Icons';
    left: 22px;
    color: green;
}
