.primaryButton {
    border: 1px solid #eb5202;
    border-radius: 0;
    display: inline-block;
    font-family: var(--font-family);
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.36;
    padding: 0.6rem 1em 0.6rem;
    text-align: center;
    vertical-align: baseline;
    color: #fff;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.25);
    background-color: #eb5202;
    width: 100%;
}

.primaryButton:hover,
.primaryButton:focus,
.primaryButton:active {
    border: 1px solid #b84002;
    background-color: #ba4000;
    text-decoration: none;
    box-shadow: none;
    color: #fff;
}

.primaryButton:after {
    border-style: solid;
    content: '';
    height: 0;
    position: absolute;
    top: 0;
    width: 0;
    border-width: 1.7rem 0 1.6rem 1.7rem;
    left: 100%;
    margin-left: -1.7rem;
    border-color: transparent transparent transparent #eb5202;
}

.primaryButton:hover:after,
.primaryButton:focus:after,
.primaryButton:active:after {
    border-left-color: #ba4000;
}
