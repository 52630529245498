.sc-tooltip {
    position: relative;
    cursor: help;
    display: inline-block;
    width: 25px;
    height: 30px;
    text-align: center;
}

.cs-tooltip-default-margin {
    margin: 0 15px;
}

.sc-tooltip:before {
    color: #514943;
    content: '\e633';
    font-family: 'Admin Icons';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    font-size: 20px;
}

.sc-tooltip-container {
    position: absolute;
    width: 32rem;
    bottom: 28px;
    display: none;
    opacity: 0;
    transition: opacity ease 0.5s;
    font-family: var(--font-family);
    z-index: 999;
}

.sc-tooltip-container-left {
    right: -24px;
}

.sc-tooltip-container-right {
    left: -24px;
}

.sc-tooltip-container-left:before,
.sc-tooltip-container-left:after {
    right: 2rem;
}

.sc-tooltip-container-right:before,
.sc-tooltip-container-right:after {
    left: 2rem;
}

.sc-tooltip-container-inner {
    background: #fffbbb;
    padding: 20px;
    width: 100%;
    border: 1px solid #afadac;
    margin-bottom: 2rem;
    color: #41362f;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    line-height: 1.36;
    font-size: 1.4rem;
    text-align: left;
    z-index: 999;
}

.sc-tooltip-container:before {
    border: 1.6rem solid transparent;
    height: 0;
    width: 0;
    border-top-color: #afadac;
    border-bottom: 0;
    content: '';
    display: block;
    position: absolute;
    bottom: 4px;
    z-index: 3;
}

.sc-tooltip-container:after {
    border: 1.6rem solid transparent;
    height: 0;
    width: 0;
    border-top-color: #fffbbb;
    border-bottom: 0;
    content: '';
    display: block;
    position: absolute;
    bottom: 5px;
    margin-top: -1.5px;
    z-index: 4;
}

.sc-tooltip-link {
    text-decoration: underline;
}

.sc-tooltip-container:hover,
.sc-tooltip-active {
    display: block;
    opacity: 1;
}
