.accordion {
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;
    margin: 0;
    padding: 0;
}

.title {
    border: 0;
    margin: 0;
    display: block;
    position: relative;
    padding: 10px 28px 10px 0;
    color: var(--font-color);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.15s linear;
}

.title::after {
    position: absolute;
    top: 25px;
    right: 13px;
    font: normal 18px/18px 'Admin Icons';
    content: '\e616';
    font-style: normal;
}

.accordion_opened .title::after {
    content: '\e615';
}

.header {
    margin-bottom: 6px;
    font: 600 17px/23px var(--font-family);
}

.text {
    font: normal 14px/18px var(--font-family);
}

.contentWrapper {
    max-height: 0;
    transform: scaleY(0);
    transform-origin: 0 0;
    transition: transform 100ms ease-in-out, max-height 100ms ease-in-out,
        visibility 100ms ease-in-out;
    visibility: hidden;
}

.accordion_opened .contentWrapper {
    max-height: 100%;
    transform: none;
    visibility: visible;
}

.content {
    padding: 30px 0 30px;
    min-width: 0;
}
