/* variables */
:root {
    --font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    --font-color: #373330;
}
/* fonts */
@font-face {
    font-family: 'Admin Icons';
    src: url('design/fonts/admin-icons/admin-icons.woff2') format('woff2'),
        url('design/fonts/admin-icons/admin-icons.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('design/fonts/opensans/light/opensans-300.woff2') format('woff2'),
        url('design/fonts/opensans/light/opensans-300.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('design/fonts/opensans/regular/opensans-400.woff2') format('woff2'),
        url('design/fonts/opensans/regular/opensans-400.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('design/fonts/opensans/semibold/opensans-600.woff2')
            format('woff2'),
        url('design/fonts/opensans/semibold/opensans-600.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('design/fonts/opensans/bold/opensans-700.woff2') format('woff2'),
        url('design/fonts/opensans/bold/opensans-700.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
