.tab-list {
    margin: 46px 0 15px;
}

.error-icon::after {
    margin-left: 10px;
    color: red;
    content: '\e632';
    font: normal 15px 'Admin Icons';
}
