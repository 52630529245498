.account-status {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: var(--font-color);
    font-family: var(--font-family);
    margin-bottom: 20px;
    font-size: 12px;
}

.account-status {
    height: 20px;
}

.last-refresh-time {
    font-weight: normal;
}

.error-icon {
    margin-left: 10px;
}

.error-icon::before {
    margin-right: 7px;
    content: '\e632';
    color: red;
    font: 500 12px 'Admin Icons';
}

.text {
    margin-bottom: 30px;
}

.issue-error {
    font-weight: bold;
    color: #e26c0b;
}

.issue-critical {
    font-weight: bold;
    color: red;
}
