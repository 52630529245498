.radio-button:checked,
.radio-button:not(:checked) {
    position: absolute;
    left: -9999px;
}

.radio-button:checked + label,
.radio-button:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    letter-spacing: 0.44px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #303030;
    font-family: var(--font-family);
}

.radio-button.radio-button:checked + label:before,
.radio-button:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 16px;
    height: 16px;
    border: 1px solid #b6b6b6;
    border-radius: 100%;
    background: #ffffff;
}

.radio-button:checked + label:after,
.radio-button:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #646160;
    position: absolute;
    top: 5px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.radio-button:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.radio-button:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
