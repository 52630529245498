.description {
    margin-top: 13px;
}

.attributes-container {
    margin-left: 19px;
}

.condition-data-grid-td {
    border-bottom: 0.1rem solid #d6d6d6;
    width: 50%;
    padding: 38px 20px 64px 24px;
    vertical-align: top;
}

.header3 {
    margin: 0 0 20px;
}

.header3-error {
    height: 19px;
    font-size: 14px;
    line-height: 18px;
    color: red;
    margin-left: 10px;
    font-weight: normal;
}

.radio-label {
    font-size: 14px;
}

.option-content {
    margin: 10px 0 0 28px;
}

.option-block:not(:last-child) {
    margin-bottom: 40px;
}

.table-data-grid-td {
    border-bottom: 0.1rem solid #d6d6d6;
    width: 50%;
    padding: 38px 20px 64px 24px;
    vertical-align: top;
}

.table-data-grid-td:last-child {
    padding: 45px 20px 64px 24px;
}
