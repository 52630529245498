.actions {
    margin-top: 1rem;
    padding-right: 0;
}
.actionWrap {
    min-width: 8.5rem;
    display: inline-block;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    position: relative;
}

.actionWrap:first-of-type {
    padding-left: 1.6rem;
}

.actionWrap:last-of-type {
    padding-right: 1.6rem;
}

.allUnset,
.allUnset:visited,
.allUnset:hover,
.allUnset:active {
    all: unset;
}
