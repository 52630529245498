.header2 {
    margin: 0;
}

.description {
    font: 600 16px / calc(28 / 16) var(--font-family);
    margin: 20px 0 0;
}

.popupWarning {
    display: flex;
    font: 400 16px/28px var(--font-family);
    color: var(--font-color);
    vertical-align: text-top;
}

.popupWarning::before {
    align-self: center;
    font: normal 21px 'Admin Icons';
    content: '\E623';
    margin-right: 5px;
}

.step-info {
    font: 400 16px / calc(28 / 16) var(--font-family);
    color: #373330;
    margin: 27px 0 0;
}

.step-info-header-container {
    display: flex;
    align-items: flex-start;
}

.step-info-header-thumbnail {
    width: 32px;
    height: 32px;
    margin: 6px 20px 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
}

.step1-info-header-thumbnail {
    background-image: url('design/assets/google-logo.png');
}

.step2-info-header-thumbnail {
    background-image: url('design/assets/merchantcenter.png');
}

.step3-info-header-thumbnail {
    background-image: url('design/assets/ads.png');
}

.step-info-header {
    font-weight: 600;
    margin: 0;
}

.step-info-note {
    margin: 0;
}

.step-info-list {
    list-style: none;
    padding: 0 0 0 14px;
}

.step-info-list-item {
    position: relative;
}

.step-info-list-item:before {
    content: '\2022';
    position: absolute;
    left: -14px;
}

.buttons-container {
    float: right;
    width: auto;
    height: auto;
    margin: 30px 0 0;
}

.button {
    height: 45px;
    font: 16px / calc(22 / 16) var(--font-family);
}

.button:after {
    content: '';
    clear: both;
}

.proceed-button,
.proceed-button:focus {
    float: right;
    height: 45px;
    width: 177px;
    margin: 30px 0 0;
    border: none;
    color: #ffffff;
    font: 600 16px / calc(22 / 16) var(--font-family);
    text-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    background-color: #eb5202;
}

.proceed-button:hover,
.proceed-button:active {
    background-color: #ba4000;
    color: #ffffff;
}

.faq-link {
    display: inline-block;
    height: 45px;
    margin: 30px 0 0;
    padding: 10px 0;
    font: 400 16px / calc(22 / 16) var(--font-family);
    color: #337ab7;
    text-decoration: none;
}

.faq-link:hover,
.faq-link:focus {
    text-decoration: none;
}

.faq-link:visited {
    color: #337ab7;
}
