.header2 {
    margin: 0 0 28px;
}

.font-medium {
    margin: 0 0 65px;
}

.button {
    outline: none;
    float: right;
    height: 45px;
    font: 600 16px / calc(22 / 16) var(--font-family);
}

.button:after {
    content: '';
    clear: both;
}

.cancel-button {
    min-width: 120px;
    padding: 0 16px;
    margin: 0 15px 0;
    border: 1px solid transparent;
    color: #303030;
    transition: background-color 0.2s;
    background-color: transparent;
}

.cancel-button:hover {
    border-color: #aeadac;
    background-color: #e3e3e3;
}

.confirm-button,
.confirm-button:hover {
    min-width: 138px;
    padding: 0 16px;
    border: 0;
    background-color: #544843;
    color: #ffffff;
}
