.nav-container {
    display: flex;
}

.link,
.link:hover,
.link:focus,
.link:active {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 11px;
    font: 600 16px var(--font-family);
    outline: none;
}

.cancel-button {
    min-width: 114px;
    height: 45px;
    margin-right: 11px;
    color: #303030;
    border: 1px solid transparent;
    background-color: transparent;
    transition: background-color 0.2s;
}

.cancel-button:hover,
.cancel-button:focus,
.cancel-button:active {
    text-decoration: none;
    border: 1px solid #aeadac;
    background-color: #e3e3e3;
}

.review-button {
    margin-right: 10px;
    height: 45px;
    min-width: 178px;
    border: none;
    color: #ffffff;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.25);
    background-color: #eb5202;
}

.review-button:hover,
.review-button:focus,
.review-button:active {
    background-color: #ba4000;
    text-decoration: none;
    color: #fff;
}
