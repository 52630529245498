.disapproved-icon::before {
    margin-right: 7px;
    content: '\e632';
    color: red;
    font: 500 12px 'Admin Icons';
}

.pending-icon > svg,
.approved-icon > svg {
    width: 14px;
    height: 14px;
    margin: 0 7px -2px 0;
}

.approved-icon > svg {
    margin: 0 5px -2px 0;
}
