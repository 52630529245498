.store-view-cell {
    position: relative;
    width: 297px;
    padding: 2px;
}

.store-view-name-cell {
    padding: 2px;
    width: 473px;
}

.remove-button {
    position: absolute;
    right: -30px;
    top: 2px;
    width: 28px;
    height: 28px;
}

.remove-button:hover {
    cursor: pointer;
}

.remove-button:after {
    font-family: 'Admin Icons';
    content: '\E630';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    color: #33302e;
    font-size: 20px;
    text-align: center;
    transition: color 0.2s;
}

.remove-button:hover:after {
    color: #757575;
}
