.table-container {
    position: relative;
}

.counter {
    position: absolute;
    z-index: 2;
    padding-left: 5px;
    margin: 0;
    font-family: var(--font-family);
}

.ReactTable {
    border: none;
    font-family: var(--font-family);
}

.ReactTable_disabled {
    opacity: 0.4;
    pointer-events: none;
    user-select: none;
}

.ReactTable .rt-noData {
    background: none;
    top: 50%;
    padding: 0;
    font-size: 16px;
    color: #303030;
}

.ReactTable .-loading.-active .-loading-inner {
    font-size: 16px;
    font-weight: 600;
}

.ReactTable .pagination-top {
    margin-bottom: 20px;
}

.ReactTable .pagination-bottom {
    margin-top: 20px;
}

.ReactTable .-pagination {
    justify-content: flex-end;
    border: none;
    padding: 0px;
    box-shadow: none;
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
    flex: none;
    height: 33px;
}

.ReactTable .-pagination .-previous {
    margin-right: 10px;
}

.ReactTable .-pagination .-center {
    flex: none;
    justify-content: flex-end;
    align-items: flex-start;
    order: 2;
}

.ReactTable .-pagination .-pageInfo,
.ReactTable .-pagination .-pageSizeOptions {
    height: 100%;
    margin: 0px 25px;
}

.ReactTable .-pagination .-pageSizeOptions {
    margin: 0px;
}

.ReactTable .-pagination .-pageJump,
.ReactTable .-pagination .-pageJump > input {
    height: 100%;
}

.ReactTable .-pagination .-pageSizeOptions,
.ReactTable .-pagination .-pageSizeOptions > select {
    height: 100%;
}

.ReactTable .-pagination .-btn {
    width: 44px;
    height: 33px;
    padding: 6px 14px;
    border: 1px solid #adadad;
    border-radius: 0px;
    font-family: 'Admin Icons';
    color: #514943;
    font-size: 14px;
    background: #e3e3e3;
}

.ReactTable .-pagination .-btn:not(:disabled):hover {
    background-color: #dbdbdb;
    color: #514943;
    text-decoration: none;
}

.ReactTable .-pagination .-btn::before {
    font-weight: 700;
}

.ReactTable .-pagination .-previous .-btn::before {
    content: '\e629';
}

.ReactTable .-pagination .-next .-btn::before {
    content: '\e62a';
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background: #e5f7fe;
}

.ReactTable .rt-thead,
.ReactTable .rt-tr {
    flex: none;
}

.ReactTable .rt-thead .rt-th {
    padding: 10px;
    border-right: 1px solid #8a837f;
    outline: none;
    color: white;
    text-align: left;
    background-color: #514943;
}

.ReactTable .rt-thead .rt-th:hover {
    background-color: #5f564f;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc,
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: none;
}

.ReactTable .rt-thead .rt-th.-sort-asc::before,
.ReactTable .rt-thead .rt-td.-sort-asc::before,
.ReactTable .rt-thead .rt-th.-sort-desc::before,
.ReactTable .rt-thead .rt-td.-sort-desc::before {
    margin-top: -2px;
    position: absolute;
    right: 10px;
}

.ReactTable .rt-thead .rt-th.-sort-asc::before,
.ReactTable .rt-thead .rt-td.-sort-asc::before {
    content: '\2193';
}

.ReactTable .rt-thead .rt-th.-sort-desc::before,
.ReactTable .rt-thead .rt-td.-sort-desc::before {
    content: '\2191';
}

.ReactTable .rt-table {
    border: 1px solid #d6d6d6;
}

.ReactTable .rt-td {
    padding: 10px;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
    white-space: normal;
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
    border: 1px solid #adadad;
    border-radius: 0px;
}

.ReactTable.-striped .rt-tr.-odd {
    background: none;
}

.ReactTable.-striped .rt-tr.-even {
    background: rgba(0, 0, 0, 0.03);
}

.ReactTable .rt-tbody .rt-td {
    border-right: none;
}

.ReactTable .rt-tbody .dashed-border {
    border-right: 1px dashed #d6d6d6;
}

.ReactTable .rt-tbody .rt-tr-group {
    border-bottom: none;
}

.ReactTable .-pagination .-pageJump > input[type='number'] {
    -moz-appearance: textfield;
}

.ReactTable
    .-pagination
    .-pageJump
    > input[type='number']::-webkit-outer-spin-button,
.ReactTable
    .-pagination
    .-pageJump
    > input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
