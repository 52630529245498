.header-wrapper {
    margin: 0 0 9px;
}

.input {
    height: 33px;
    width: 100%;
    padding: 0 45px 1px 35px;
}

.input-wrapper {
    position: relative;
    width: 152px;
}

.input-container {
    position: relative;
    width: 152px;
    height: 62px;
}

.input-right-content {
    position: absolute;
    top: 6px;
    right: 12px;
}

.input-left-content {
    position: absolute;
    top: 6px;
    left: 9px;
}
