.table-wrapper {
    position: relative;
}

.status-paused > svg,
.status-removed > svg,
.status-enabled > svg {
    width: 14px;
    height: 14px;
    margin: 0 7px -2px 0;
}

.status-enabled > svg {
    margin: 0 5px -2px 0;
}

.action-container {
    position: absolute;
}

.modal-container {
    width: 699px;
    padding: 30px 30px 26px;
}

.last-sync-time {
    position: absolute;
    top: 17px;
    font: normal 12px var(--font-family);
    color: var(--font-color);
    z-index: 1;
}
