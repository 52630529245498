.general-attributes-form {
    margin-top: 30px;
}

.general-attributes-data-grid tr td {
    border-bottom: 0.1rem solid #d6d6d6;
    width: 50%;
    padding: 38px 20px 64px 24px;
    vertical-align: top;
}

.general-attributes-data-grid h2 {
    margin: 0;
}

.header3 {
    margin: 39px 0 0 0;
}

.general-attributes-text {
    margin: 6px 0 37px 0;
}
