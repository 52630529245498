.button {
    border: 1px solid #adadad;
    border-radius: 0;
    display: inline-block;
    font-family: var(--font-family);
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.36;
    padding: 0.6rem 1em 0.6rem;
    text-align: center;
    vertical-align: baseline;
    background: #e3e3e3;
    color: #514943;
    text-indent: -0.85rem;
    width: 100%;
}

.button:hover {
    background-color: #dbdbdb;
    color: #514943;
    text-decoration: none;
    box-shadow: none;
}

.button:before {
    border-style: solid;
    content: '';
    height: 0;
    position: absolute;
    top: 0;
    width: 0;
    border-color: transparent #e3e3e3 transparent transparent;
    border-width: 1.7rem 1.7rem 1.6rem 0;
    margin-right: -1.7rem;
    right: 100%;
    border-right-color: #949494;
    left: -1px;
}

.button:after {
    border-style: solid;
    content: '';
    height: 0;
    position: absolute;
    top: 0;
    width: 0;
    border-color: transparent #e3e3e3 transparent transparent;
    border-width: 1.7rem 1.7rem 1.6rem 0;
    margin-right: -1.7rem;
    right: 100%;
}

.button:hover:after {
    border-right-color: #dbdbdb;
}
