.table-data-grid-td {
    border-bottom: 0.1rem solid #d6d6d6;
    width: 50%;
    padding: 38px 20px 64px 24px;
    vertical-align: top;
}

.table-data-grid-td:last-child {
    padding: 45px 20px 64px 24px;
}
