.modal-container {
    box-sizing: border-box;
    width: 794px;
    padding: 38px 52px;
}

.header2 {
    margin: 0 0 25px;
    font-weight: 600;
}

.text {
    margin: 0;
    font: normal 16px/24px var(--font-family);
    color: var(--font-color);
}

.text-block {
    margin: 0 0 10px;
}

.list-block {
    margin: 0 0 25px;
    padding-left: 17px;
}

.list-item {
    list-style-type: disc;
}

.list-item_text {
    margin: 0;
    font: italic 16px/28px var(--font-family);
    color: var(--font-color);
}

.button-base {
    width: 118px;
    height: 45px;
    padding: 14px 16px;
    font: 600 16px/1 var(--font-family);
    text-align: center;
    float: right;
}

.button,
.button:hover,
.button:focus,
.button:active {
    box-sizing: border-box;
    text-decoration: none;
    color: white;
    background-color: #544843;
    border: 0;
    outline: none;
}

.cancel {
    margin-right: 16px;
}

.button:after {
    content: '';
    clear: both;
}
