.header2 {
    margin: 0 0 28px;
}

.font-medium {
    margin: 0 0 27px;
}

.button {
    float: right;
    height: 45px;
    font: 16px / calc(22 / 16) var(--font-family);
}

.button:after {
    content: '';
    clear: both;
}

.cancel-button,
.cancel-button:hover {
    margin: 0 15px 0 0;
    width: 120px;
    border: 1px solid #aeadac;
    background-color: #e3e3e3;
    color: #303030;
}

.confirm-button,
.confirm-button:hover {
    width: 171px;
    border: 0;
    background-color: #544843;
    color: #ffffff;
}
