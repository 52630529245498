.checkbox {
    appearance: none;
    background: #fff;
    border-radius: 2px;
    border: 1px solid #adadad;
    cursor: pointer;
    display: inline-block;
    height: 16px;
    margin-top: 0 !important;
    position: relative;
    transition: all 0.1s ease-in;
    vertical-align: text-bottom;
    width: 16px;
}

.checkbox:checked:after {
    font-family: 'Admin Icons';
    content: '\e62d';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 14px;
    color: #514943;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    font-weight: 400;
}

.label {
    margin: 0 0 3px 10px;
    color: var(--font-color);
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    line-height: 1.36;
    font-size: 1.4rem;
}

.checkboxError {
    margin-left: 26px;
    color: #d0021b;
    font-family: var(--font-family);
    font-size: 13.5px;
    line-height: 18px;
}

.checkbox-disabled {
    opacity: 0.4;
}
