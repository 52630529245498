.flat-rate-form-data-grid-td {
    width: 50%;
    border: 0;
    padding: 8px;
    vertical-align: top;
}

.flat-rate-form-data-grid-td:first-child {
    padding: 16px 0 15px 9px;
    border-right: none;
}

.flat-rate-form-data-grid-td:last-child {
    border-left: none;
}

.flat-rate-form-country-name {
    color: #303030;
    font: 600 13px / calc(17 / 13) var(--font-family);
    margin: 0;
}

.input {
    height: 33px;
    width: 100%;
    padding: 0 45px 1px 29px;
}
