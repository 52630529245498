.configurable-select {
    position: relative;
}

.configurable-select-left-required {
    margin: 0 0 0 19px;
}

.configurable-select-left-required:before {
    position: absolute;
    left: -21px;
    top: 5px;
    font-size: 24px;
    content: '*';
    color: red;
}

.configurable-select-disabled {
    opacity: 0.4;
}
