.modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.modal-container-main {
    position: relative;
    background-color: #ffffff;
    display: inline-block;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
}

.close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 35px;
    width: 22px;
    height: 22px;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;
    opacity: 1;
}

.close:hover {
    opacity: 0.5;
    cursor: pointer;
}

.close:before,
.close:after {
    position: absolute;
    top: 26px;
    left: 30px;
    content: ' ';
    height: 27px;
    width: 2px;
    background-color: #979797;
}

.close:before {
    transform: rotate(45deg);
}

.close:after {
    transform: rotate(-45deg);
}
