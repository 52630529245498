.action-controls {
    float: right;
}

.link,
.link:hover,
.link:focus,
.link:active {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 11px;
}

.create-ad-button,
.create-ad-button:hover,
.create-ad-button:focus,
.create-ad-button:active {
    height: 45px;
    width: 199px;
    margin-left: 11px;
    border: none;
    color: #ffffff;
    font: 600 16px / calc(22 / 16) var(--font-family);
    text-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    background-color: #eb5202;
}

.create-ad-button_disabled {
    background-color: rgb(242, 181, 149);
    pointer-events: none;
}

.create-gmc-button,
.create-gmc-button:hover,
.create-gmc-button:focus,
.create-gmc-button:active {
    height: 45px;
    width: 192px;
    margin-left: 11px;
    border: none;
    color: #ffffff;
    font: 600 16px / calc(22 / 16) var(--font-family);
    text-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    background-color: #544843;
}

.settings-button,
.settings-button:hover,
.settings-button:focus,
.settings-button:active {
    height: 45px;
    width: 109px;
    font: 600 16px / calc(22 / 16) var(--font-family);
    color: #303030;
    background-color: transparent;
    border-color: transparent;
}
