.description {
    margin-top: 13px;
}

.attributes-container {
    margin-left: 19px;
}

.header3 {
    margin: 0 0 20px;
}

.header3-error {
    height: 19px;
    font-size: 14px;
    line-height: 18px;
    color: red;
    margin-left: 10px;
    font-weight: normal;
}

.radio-label {
    font-size: 14px;
}

.option-content {
    margin: 10px 0 0 28px;
}

.option-block:not(:last-child) {
    margin-bottom: 40px;
}

.radio-container {
    display: flex;
}
