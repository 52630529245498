.header {
    font: 600 24px / calc(33 / 24) var(--font-family);
    color: #373330;
    margin: 31px 0 21px;
}

.status-period {
    font: 600 16px / calc(22 / 16) var(--font-family);
    color: #373330;
}

.status-bar {
    text-align: center;
    padding: 0 0 17px;
    border-bottom: 1px solid #e0e0e0;
}

.status-item-container {
    display: inline-block;
    width: 20%;
    margin: 45px 0 0;
    vertical-align: top;
}

.reports-link-container {
    display: inline-flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 20%;
    height: 124px;
    padding: 18px;
    font: 600 11px var(--font-family);
    color: black;
    text-align: left;
    background-color: #f1f1f1;
}

.reports-link-container-header {
    font-weight: 900;
}

.status-item {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.status-value {
    font: 600 20px / calc(27 / 20) var(--font-family);
    color: #373330;
    margin: 0 0 11px;
}

.bi-logo-container {
    width: 100%;
    margin: 0 0 15px;
}

.bi-logo {
    background: url('design/assets/YuiBI-logo-x2.png') center/124px
        no-repeat;
    height: 51px;
}

.view-reports-button,
.view-reports-button:hover,
.view-reports-button:focus,
.view-reports-button:active {
    display: inline-block;
    height: 33px;
    width: 178px;
    padding: 6px;
    border: 1px solid #aeadac;
    color: #303030;
    font: 600 14px / calc(19 / 14) var(--font-family);
    text-decoration: none;
    background-color: #e3e3e3;
}
