.header-wrapper {
    margin: 0 0 9px;
}

.input-container {
    position: relative;
    height: 62px;
}

.input-wrapper {
    width: 300px;
}
