.multicheck {
    position: relative;
    width: 31px;
    height: 16px;
    display: inline-block;
    z-index: 1;
    display: flex;
}

.multicheck-label {
    width: 15px;
    height: 16px;
    display: inline-block;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
}

.multicheck-label::before {
    display: inline-block;
    width: 15px;
    height: 16px;
    box-sizing: border-box;
    border: 1px solid #adadad;
    border-right: none;
    border-radius: 1px;
    color: #514943;
    font-family: 'Admin Icons';
    content: '';
    font-size: 0;
    text-align: center;
    vertical-align: top;
    background-color: #fff;
    transition: font-size 0.1s ease-out, color 0.1s ease-out,
        border-color 0.1s linear;
}

.multicheck-label_part::before {
    content: '-';
    font: bold 14px/100% var(--font-family);
}

.multicheck-label_all::before {
    content: '\e62d';
    font: 11px/125% 'Admin Icons';
}

.multicheck-button {
    position: relative;
    width: 16px;
    height: 16px;
    display: inline-block;
    border: 1px solid;
    border-radius: 0 1px 1px 0;
    border-color: #adadad;
    padding: 0;
    font-family: var(--font-family);
    outline: none;
}

.multicheck-button_reversed::after {
    transform: rotate(180deg);
}

.multicheck-button::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    right: 3px;
    margin-top: -2px;
    border-width: 5px 4px 0;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
    transition: all 0.2s linear;
}

.multicheck-list {
    display: none;
    position: absolute;
    top: 100%;
    right: auto;
    left: -10px;
    margin-top: 2px;
    padding: 0;
    border: 1px solid #007bdb;
    border-radius: 1px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    color: #41362f;
    list-style: none;
    background-color: #fff;
}

.multicheck-list_opened {
    display: block;
}

.multicheck-item {
    padding: 9px 13px;
    font: normal 13px var(--font-family);
    white-space: nowrap;
    text-align: left;
    cursor: pointer;
}

.multicheck-item:hover {
    background-color: #e3e3e3;
}
