.wrapper {
    padding: 34px 50px;
}

.header {
    margin: 0 0 23px;
}

.header3 {
    margin: 23px 0 6px;
}

.action-controls {
    float: right;
}

.cancel-button,
.cancel-button:hover,
.cancel-button:focus,
.cancel-button:active {
    margin-right: 23px;
    height: 45px;
    min-width: 93px;
    color: #303030;
    font: 600 16px / calc(22 / 16) var(--font-family);
    background-color: transparent;
    border-color: transparent;
    outline: none;
}

.arrow::before {
    content: '\e626';
    margin-right: 20px;
    font: 500 16px 'Admin Icons';
}

.product-status {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: var(--font-color);
    font-family: var(--font-family);
    margin-bottom: 5px;
}

.affected-number {
    font-size: 18px;
    font-weight: 600;
}

.last-refresh-time {
    font-size: 12px;
    font-weight: normal;
}

.text {
    margin-bottom: 16px;
}

.edit-link_disabled {
    color: grey;
    cursor: not-allowed;
    opacity: 0.5;
}
