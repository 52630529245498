.header2 {
    margin-bottom: 10px;
}

.description-text {
    margin: 0 0 26px 5px;
    letter-spacing: 0.28px;
}

.critical-error-color {
    color: red;
}
