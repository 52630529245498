.options-mapping-form {
    margin: 30px 0 0 28px;
}

.options-mapping-form-disabled {
    opacity: 0.7;
}

.options-mapping-form tr td {
    border-bottom: 0;
}

.options-mapping-form tr:nth-child(even) {
    background-color: #f5f5f5;
}

.options-mapping-form tr {
    border-left: 1px solid #d6d6d6;
    border-right: 1px solid #d6d6d6;
}

.options-mapping-form tr:last-child {
    border-bottom: 1px solid #d6d6d6;
}

.options-mapping-form-data-grid-td {
    width: 50%;
    border: 0 !important;
    padding: 16px 0 15px 10px;
    vertical-align: top;
}

.options-mapping-form-data-grid-td:first-child {
    padding: 16px 0 15px 9px;
}

.options-mapping-form-data-grid-td:last-child {
    padding: 8px;
}

.options-mapping-form-data-grid-td p {
    color: #303030;
    font: 600 13px / calc(17 / 13) var(--font-family);
    margin: 0;
}

.header3 {
    margin: 39px 0 0;
}

.apparel-accessories-text {
    margin: 6px 0 37px;
}

.bottom-error {
    margin: 16px 0 0 29px;
}
