.required-header-container {
    margin: 38px 0 0 0;
}

.required-header3 {
    position: relative;
    letter-spacing: 0.4px;
}

.required-header3:after {
    position: absolute;
    top: 4px;
    right: -16px;
    content: '*';
    font-size: 16px;
    color: red;
}

.required-header-error {
    height: 19px;
    font-size: 14px;
    line-height: 18px;
    color: red;
    margin-left: 31px;
}

.font-medium {
    margin: 6px 0 30px 0;
}
