.field-row {
    width: auto;
}

.field-row:not(:last-child) {
    margin-bottom: 13px;
}

.field-name {
    display: inline-block;
    width: 113px;
    margin-right: 11px;
}

.acc-id-field {
    color: #4385bd;
}

.field-block {
    margin-bottom: 23px;
}

.payment-text {
    margin-bottom: 20px;
}

.checkbox {
    width: 17px;
    height: 17px;
    appearance: none;
    background: #fff;
    border: 1px solid #adadad;
    cursor: pointer;
    display: inline-block;
    margin: 0 10px 0 0 !important; /* @todo: change after disabling bootstrap */
    position: relative;
    transition: all 0.1s ease-in;
    vertical-align: text-bottom;
}

.checkbox:checked:after {
    content: '\e62d';
    display: inline-block;
    position: absolute;
    top: 1px;
    left: 0;
    width: 15px;
    font: normal 12px/13px 'Admin Icons';
    color: #514943;
    text-align: center;
}

.checkbox:focus,
.checkbox:active {
    outline: none !important; /* @todo: change after disabling bootstrap */
}

.label {
    color: var(--font-color);
    font: 400 14px var(--font-family);
    margin: 0;
}

.label-text {
    cursor: pointer;
}

.buttons-block {
    margin-top: 22px;
}

.google-ads-link {
    display: inline-block;
    padding: 7px 17px;
    border: 1px solid #aeadac;
    font: 600 13px var(--font-family);
    color: #303030;
    text-align: center;
    text-decoration: none;
    background-color: #e3e3e3;
}

.google-ads-link:hover,
.google-ads-link:focus,
.google-ads-link:active {
    background-color: #dbdbdb;
    color: #514943;
    text-decoration: none;
}
