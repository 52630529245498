.field-wrapper {
    margin: 27px 0 0;
}

.field-header-wrapper {
    margin: 0 0 10px;
}

.currency-field {
    margin-top: 20px;
}
