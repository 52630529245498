.react-tabs__tab-list {
    border-bottom: 1px solid #e0e0e0;
    margin: 0;
    padding: 0;
}

.react-tabs__tab {
    position: relative;
    display: inline-block;
    width: 179px;
    border-radius: 0;
    border: 1px solid #cecece;
    border-bottom: none;
    bottom: -1px;
    list-style: none;
    padding: 17px 0;
    font: 700 14px / calc(27 / 14) var(--font-family);
    color: #373330;
    text-align: center;
    cursor: pointer;
    outline: none;
}

.react-tabs__tab--big {
    min-width: 235px;
    width: auto;
    border: none;
    padding: 0 5px 25px;
    font: 600 20px/23px var(--font-family);
}

.react-tabs--selected {
    background-color: white;
}

.react-tabs--selected:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 5px;
    width: 100%;
    background-color: #e95103;
}

.react-tabs--selected-big:before {
    top: initial;
    bottom: 0;
    background-color: #514943;
}

.react-tabs__tab:not(:last-child) {
    margin: 0 6px 0 0;
}

.react-tabs__tab--big:not(:last-child) {
    margin-right: 0;
}
