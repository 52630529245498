.fields-container {
    margin: 0 0 0 26px;
}

.checkbox-container {
    margin: 5px 0 0 0;
}

.select-container {
    margin: 10px 0 10px 0;
    min-height: 87px;
}

.select-header {
    display: inline-block;
    margin: 0 0 11px 0;
    opacity: 0.5;
}
