.leftMargin {
    margin-left: 59px;
}

.header1 {
    height: 27px;
    color: var(--font-color);
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 27px;
    margin-top: 40px;
}

.text1 {
    margin-top: 16px;
    width: 607px;
    color: var(--font-color);
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 21px;
}

.link {
    margin-top: 10px;
    height: 22px;
    color: #337ab7;
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 19px;
}

.header2 {
    margin-top: 27px;
    height: 23px;
    color: var(--font-color);
    font-family: var(--font-family);
    font-size: 17px;
    font-weight: 600;
    line-height: 23px;
}

.text2 {
    margin-top: 13px;
    width: 730px;
    color: var(--font-color);
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 21px;
}

.consent {
    margin-top: 28px;
}
