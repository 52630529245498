.action-select {
    display: inline-block;
    position: relative;
    font-family: var(--font-family);
    font-size: 14px;
    outline: none;
}

.label-text {
    color: #4385bd;
    user-select: none;
    cursor: pointer;
}

.label-text::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    margin: -2px 0 0 5px;
    border-width: 5px 4px 0;
    border-style: solid;
    border-color: #4385bd transparent transparent transparent;
    transition: all 0.2s linear;
}

.label-text_reversed::after {
    transform: rotate(180deg);
}

.options {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100px;
    border: 1px solid #979797;
    margin: 5px 0 0;
    padding: 0;
    background-color: white;
    z-index: 1;
}

.options_hidden {
    display: none;
}

.option {
    display: block;
    padding: 7px;
    list-style: none;
    cursor: pointer;
    user-select: none;
}

.option_disabled {
    cursor: default;
    color: #c1c1c1;
}

.option:hover {
    background-color: #e3e3e3;
}

.option_disabled:hover {
    background-color: white;
}

.no-options {
    min-width: 100px;
    text-align: center;
    padding: 7px;
}
