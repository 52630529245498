.nav-container {
    display: flex;
}

.link,
.link:hover,
.link:focus,
.link:active {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 11px;
    font: 600 16px var(--font-family);
    outline: none;
}

.back-button,
.back-button:hover,
.back-button:focus,
.back-button:active {
    margin-right: 21px;
    height: 45px;
    min-width: 93px;
    color: #303030;
    background-color: transparent;
    border-color: transparent;
}

.arrow::before {
    content: '\e626';
    margin-right: 20px;
    font: 500 16px 'Admin Icons';
}

.cancel-button {
    min-width: 114px;
    height: 45px;
    margin-right: 11px;
    color: #303030;
    border: 1px solid transparent;
    background-color: transparent;
    transition: background-color 0.2s;
}

.cancel-button:hover,
.cancel-button:focus,
.cancel-button:active {
    text-decoration: none;
    border: 1px solid #aeadac;
    background-color: #e3e3e3;
}

.save-button {
    margin-right: 10px;
    height: 45px;
    min-width: 178px;
    border: none;
    color: #ffffff;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.25);
    background-color: #eb5202;
}

.save-button:hover,
.save-button:focus,
.save-button:active {
    background-color: #ba4000;
    text-decoration: none;
    color: #fff;
}

.save-button_disabled {
    background-color: rgb(242, 181, 149);
    pointer-events: none;
}

.section {
    margin-top: 45px;
}

.gmc-block {
    max-width: 893px;
}

.gmc-no-data {
    color: var(--font-color);
    font: normal 14px var(--font-family);
}

.action-container {
    position: absolute;
}

.header2 {
    margin: 0 0 23px;
}

.google-ads-block {
    margin-top: 45px;
}

.linked-acc-block {
    margin-top: 45px;
}

.email-block:nth-child(n + 3) {
    margin-top: 27px;
}

.header3 {
    color: var(--font-color);
    font: 600 16px var(--font-family);
    margin: 0;
}

.email-field {
    margin-top: 13px;
}

.sidebar-header {
    height: auto;
}

.sidebar-content {
    box-sizing: border-box;
    padding: 45px 20px 30px 30px;
}

.version-info-row {
    width: auto;
}

.field-name {
    display: inline-block;
    width: 113px;
    margin-right: 11px;
}
