.nav-container {
    display: flex;
}

.link,
.link:hover,
.link:focus,
.link:active {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 11px;
    font: 600 16px var(--font-family);
}

.create-ad-button {
    margin-right: 10px;
    height: 45px;
    width: 199px;
    border: none;
    color: #ffffff;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.25);
    background-color: #eb5202;
}

.create-ad-button:hover,
.create-ad-button:focus,
.create-ad-button:active {
    background-color: #ba4000;
    text-decoration: none;
    color: #fff;
}

.create-ad-button_disabled {
    background-color: rgb(242, 181, 149);
    pointer-events: none;
    user-select: none;
}

.settings-button {
    width: 114px;
    height: 45px;
    margin-right: 11px;
    border: 1px solid #aeadac;
    color: #303030;
    background-color: #e3e3e3;
}

.settings-button:hover,
.settings-button:focus,
.settings-button:active {
    background-color: #dbdbdb;
    color: #514943;
    text-decoration: none;
}

.back-button,
.back-button:hover,
.back-button:focus,
.back-button:active {
    margin-right: 21px;
    height: 45px;
    min-width: 93px;
    color: #303030;
    background-color: transparent;
    border-color: transparent;
    outline: none;
}

.arrow::before {
    content: '\e626';
    margin-right: 20px;
    font: 500 16px 'Admin Icons';
}
