.middleButton {
    border: 1px solid #adadad;
    border-radius: 0;
    display: inline-block;
    font: 600 1.4rem/1.36 var(--font-family);
    padding: 0.6rem 1em;
    text-align: center;
    color: #514943;
    background-color: #e3e3e3;
    width: 100%;
    transition: background-color 0.2s;
}

.middleButton:hover {
    background-color: #dbdbdb;
    color: #514943;
    text-decoration: none;
    box-shadow: none;
}
