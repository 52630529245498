.header1,
.header2,
.header3,
.header4 {
    margin-top: 16px;
    color: var(--font-color);
    font-family: var(--font-family);
    line-height: 21px;
    font-weight: inherit;
}

.header1 {
    margin-top: 0;
    font-size: 26px;
}

.header2 {
    height: 27px;
    font-size: 20px;
    line-height: 27px;
    margin-top: 40px;
}

.header3 {
    font-size: 17px;
    line-height: 23px;
}

.medium-field-header {
    position: relative;
    margin-top: 16px;
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 21px;
}

.medium-field-header-right-required:after {
    position: absolute;
    top: 2px;
    right: -20px;
    content: '*';
    font-size: 17px;
    color: red;
}

.medium-field-header-left-required:before {
    position: absolute;
    top: 2px;
    left: -20px;
    content: '*';
    font-size: 17px;
    color: red;
}

small,
.font-small,
.font-medium {
    color: var(--font-color);
    font-family: var(--font-family);
}

.font-medium {
    font-size: 14px;
    line-height: 1.7;
}

small,
.font-small {
    font-size: 13px;
}

.font-light {
    font-weight: 300;
}

.font-regular {
    font-weight: 400;
}

.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

.sceneMainContent {
    margin: 0 24px 10px 31px;
}

.table {
    background-color: transparent;
    color: #303030;
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    border-color: grey;
}

.data-grid {
    border: none;
    font-size: 1.3rem;
    margin-bottom: 0;
    width: 100%;
}

.data-grid thead {
    background-color: transparent;
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

.data-grid th {
    background-color: #514943;
    border: 0.1rem solid #8a837f;
    border-left-color: transparent;
    color: #ffffff;
    font-weight: 400;
    padding: 0;
    text-align: left;
}

.data-grid th:first-child {
    border-left-color: #8a837f;
}

.data-grid .data-grid-th {
    background-clip: padding-box;
    color: #ffffff;
    padding: 9px 15px;
    position: relative;
    vertical-align: middle;
}

.data-grid td:first-child {
    border-left-style: solid;
}

.data-grid td:last-child {
    border-right-style: solid;
}

.data-grid td {
    border-left: 0.1rem dashed #d6d6d6;
    border-right: 0.1rem dashed #d6d6d6;
    color: #303030;
}

.data-grid tr:not(.data-grid-editable-row):last-child td {
    border-bottom: 0.1rem solid #d6d6d6;
}

.bottom-error {
    display: inline-block;
    margin: 10px 0 0 2px;
    color: #d0021b;
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 18px;
}

.main-actions-panel {
    width: 100%;
    padding: 15px 20px 16px;
    background-color: #f8f8f8;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
}

.clearfix:after {
    content: '';
    display: table;
    clear: both;
}

.flex-container {
    display: flex;
}

.space-between {
    justify-content: space-between;
}

.align-end {
    align-items: flex-end;
}

.cs-tooltip-left-margin {
    margin: 0 0 0 30px;
}
