.product-status {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: var(--font-color);
    font-family: var(--font-family);
    margin-bottom: 20px;
    font-size: 12px;
}

.product-status {
    height: 20px;
}

.last-refresh-time {
    font-weight: normal;
}

.text {
    margin-bottom: 30px;
}

.status-element {
    margin-left: 10px;
}

.total-icon {
    position: relative;
    padding-left: 22px;
}

.total-icon::before {
    position: absolute;
    top: 1px;
    left: 0;
    content: '\e608';
    font: 500 17px 'Admin Icons';
}

.disapproved-icon::before {
    margin-right: 7px;
    content: '\e632';
    color: red;
    font: 500 12px 'Admin Icons';
}

.pending-icon,
.approved-icon {
    width: 14px;
    height: 14px;
    margin: 0 7px -2px 0;
}

.approved-icon {
    margin: 0 5px -2px 0;
}

.action {
    color: #337ab7;
    text-decoration: none;
    cursor: pointer;
    outline: none;
}

.action:hover,
.action:focus {
    color: #23527c;
    text-decoration: underline;
}
