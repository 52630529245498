.header3 {
    margin-bottom: 40px;
}

.option-block {
    margin-bottom: 40px;
}

.font-semibold {
    font-weight: 600;
}
