.content-wrapper {
    margin: 33px 30px;
}

.header1 {
    margin: 0 0 33px;
}

.header3 {
    letter-spacing: 0.4px;
}

.main-content {
    padding-top: 12px;
}

.info-field {
    margin: 28px 0 0;
}

.no-of-products-remark {
    font: normal 11px var(--font-family);
}

.fetch-products-amount-block {
    position: relative;
    height: 84px;
    width: 761px;
    padding: 30px 65px;
    border: 1px solid #dcdcdc;
    box-shadow: 0 0 1px 1px rgba(235, 235, 235, 0.38);
}

.spinner {
    position: absolute;
    left: 18px;
    top: 27px;
    width: 28px;
}
