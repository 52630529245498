.header2 {
    margin-bottom: 19px;
}

.description-text {
    margin: 0 0 26px 5px;
    letter-spacing: 0.28px;
}

.tab-list {
    margin-bottom: 15px;
}

.critical-error-color {
    color: red;
}
