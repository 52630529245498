.modal-container {
    width: 699px;
    padding: 30px;
}

.header2 {
    margin: 0;
}

.header-container {
    display: flex;
    align-items: center;
}

.header-logo {
    background: url('design/assets/google-logo.png') left top no-repeat;
    width: 32px;
    height: 32px;
    margin-right: 10px;
}

.step-info {
    font: 400 16px / calc(28 / 16) var(--font-family);
    color: #373330;
    margin: 27px 0 0;
}

.step-info-header {
    font-weight: 600;
    margin: 0;
}

.step-info-note {
    margin: 0;
}

.faq-link {
    display: inline-block;
    height: 45px;
    margin: 18px 0 0;
    padding: 10px 0;
    font: 500 16px / calc(22 / 16) var(--font-family);
    color: #337ab7;
    text-decoration: none;
}

.faq-link:hover,
.faq-link:focus {
    text-decoration: none;
}

.faq-link:visited {
    color: #337ab7;
}
