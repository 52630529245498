.wizUl {
    counter-reset: i;
    float: left;
    padding: 0;
    position: relative;
    white-space: nowrap;
    margin: 0.5rem 0 0;
}

.wizUl:before {
    background: #d4d4d4 linear-gradient(to bottom, #d1d1d1 0%, #d4d4d4 100%)
        repeat-x;
    border-bottom: 1px solid #d9d9d9;
    border-top: 1px solid #bfbfbf;
    content: '';
    height: 0.8rem;
    left: 5.15rem;
    position: absolute;
    right: 5.15rem;
    top: 0.7rem;
}

.wizLi {
    display: inline-block;
    font-size: 0;
    position: relative;
    vertical-align: top;
    width: 10.3rem;
}

.wizLiPast:after {
    background-color: #514943;
    content: '';
    height: 0.5rem;
    left: calc(-50% + 0.25rem);
    position: absolute;
    right: calc(50% + 0.7rem);
    top: 0.9rem;
}

.wizA {
    text-decoration: none;
    color: #514943;
    display: block;
    font-family: var(--font-family);
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.2;
    overflow: hidden;
    padding: 3rem 0.5em 0;
    position: relative;
    text-align: center;
    text-overflow: initial;
    white-space: normal;
}

.wizA:hover {
    text-decoration: none;
    color: #514943;
}

.wizA:before {
    background-color: #d6d6d6;
    border: 1px solid transparent;
    border-radius: 100%;
    content: '';
    height: 2.1rem;
    left: 50%;
    line-height: 1;
    margin-left: -1.2rem;
    position: absolute;
    top: 0;
    width: 2.1rem;
    box-sizing: content-box;
}

.wizA:after {
    background-color: #514943;
    border: 4px solid #514943;
    border-radius: 100%;
    color: #ffffff;
    content: counter(i);
    counter-increment: i;
    height: 0.7rem;
    left: 50%;
    line-height: 0.6;
    margin-left: -0.8rem;
    position: absolute;
    right: auto;
    text-align: center;
    top: 0.4rem;
    width: 0.7rem;
    box-sizing: content-box;
}

.wizAActive:after {
    background-color: #ffffff;
    content: '';
}

.wizAInactive:after {
    background-color: transparent;
    border-color: transparent;
    color: #a6a6a6;
}
