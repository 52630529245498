.action-controls {
    float: right;
}

.cancel-button,
.cancel-button:hover,
.cancel-button:focus,
.cancel-button:active {
    height: 45px;
    width: 93px;
    color: #303030;
    font: 600 16px / calc(22 / 16) var(--font-family);
    background-color: transparent;
    border-color: transparent;
}

.save-button,
.save-button:hover,
.save-button:focus,
.save-button:active {
    height: 45px;
    width: 178px;
    margin-left: 15px;
    border: none;
    color: #ffffff;
    font: 600 16px / calc(22 / 16) var(--font-family);
    text-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    background-color: #eb5202;
}
