.mainAction {
    background: #f8f8f8;
    border-bottom: 1px solid #e3e3e3;
    border-top: 1px solid #e3e3e3;
    padding: 1.5rem;
}
.mainAction:after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}
.right {
    float: right;
}
.stick {
    position: sticky;
    top: 0;
    z-index: 2;
    margin: 10px 0 0 0;
}
