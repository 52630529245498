.table {
    width: 1039px;
}

.store-views-header-wrapper {
    margin: 0 0 12px;
}

.bottom-error {
    width: 100%;
}

.add-button {
    width: 179px;
    height: 33px;
    margin: 16px 0 37px;
    color: #333;
    border-color: #b5b4b3;
    background-color: #e3e3e3;
    transition: opacity 0.2s;
}

.add-button:hover {
    opacity: 0.9;
}
