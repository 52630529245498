.action-controls {
    float: right;
}

.publish-button,
.publish-button:focus {
    height: 45px;
    width: 178px;
    margin-left: 15px;
    border: none;
    color: #ffffff;
    font: 600 16px / calc(22 / 16) var(--font-family);
    text-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    background-color: #eb5202;
}

.publish-button:hover,
.publish-button:active {
    background-color: #ba4000;
    color: #ffffff;
}

.edit-button,
.edit-button:hover,
.edit-button:focus,
.edit-button:active {
    height: 45px;
    width: 163px;
    color: #303030;
    font: 600 16px / calc(22 / 16) var(--font-family);
    background-color: transparent;
    border-color: transparent;
}

.publish-button_disabled {
    background-color: rgb(242, 181, 149);
}
