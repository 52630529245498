.header {
    color: var(--font-color);
    font: 600 24px/33px var(--font-family);
    margin: 55px 0 15px;
}

.tables-block {
    margin-top: 30px;
    overflow: hidden;
}

.notifications-container {
    padding: 20px 0;
}
