.sidebar-overlay {
    visibility: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0);
    z-index: 998;
    transition-duration: 0.5s;
}

.sidebar-overlay_active {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 1118px;
    background-color: white;
    transition-duration: 0.5s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    overflow: auto;
}

.right-anchor {
    right: -100%;
}

.left-anchor {
    left: -100%;
}

.right-active {
    right: 0;
}

.left-active {
    left: 0;
}
