.error-container {
    height: 35px;
}

.flat-rate-table-wrapper {
    margin: 10px 0 0 26px;
    width: 311px;
}

.flat-rate-table {
    margin-bottom: 4px;
}

.flat-rate-table-disabled {
    opacity: 0.7;
}

.flat-rate-table tr td {
    border-bottom: 0;
}

.flat-rate-table tr:nth-child(even) {
    background-color: #f5f5f5;
}

.flat-rate-table tr {
    border-left: 1px solid #d6d6d6;
    border-right: 1px solid #d6d6d6;
}

.flat-rate-table tr:last-child {
    border-bottom: 1px solid #d6d6d6;
}

:root .data-grid-th {
    vertical-align: top;
    padding: 7px 0 6px 9px;
}
